import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Header } from 'facultyComponents/extensions/header/Header';
import { LoaderComponent } from 'wa-ui-components';
import { getHeaderAssignmentInfo, getHeaderStudentInfo } from './apiHelpers';
import { IHeaderAssignmentInfo, IHeaderStudentInfo, RestrictionLevel } from 'context/ExtensionsContextProvider/types';
import { EditContainer } from 'facultyComponents/extensions/edit/EditContainer';

// for testing purposes
const propsTimed = {
  isTimedAssignment: true,
};

export const StudentAssignmentPage: React.FC = () => {
  const { userId, deploymentId } = useParams<{ userId: string; deploymentId: string }>();

  const [isHeaderLoading, setHeaderLoading] = useState(true);
  const [assignmentInfo, setAssignmentInfo] = useState<IHeaderAssignmentInfo>();
  const [studentInfo, setStudentInfo] = useState<IHeaderStudentInfo>();

  useEffect(() => {
    if (!deploymentId) return;
    const fetchStudentInfo = async () => {
      if (!userId) return;
      try {
        const response = await getHeaderStudentInfo(userId, RestrictionLevel.DEPLOYMENT, deploymentId);
        setStudentInfo(response.data.result);
      } catch (error) {
        console.error('Failed to fetch student extensions:', error);
      }
    };

    const fetchAssignmentInfo = async () => {
      try {
        const response = await getHeaderAssignmentInfo(deploymentId);
        setAssignmentInfo(response.data.result);
      } catch (error) {
        console.error('Failed to fetch assignment restrictions:', error);
      }
    };

    Promise.all([fetchStudentInfo(), fetchAssignmentInfo()]).finally(() => {
      setHeaderLoading(false);
    });
  }, [deploymentId, userId]);

  return (
    <>
      {isHeaderLoading ? <LoaderComponent /> : <Header assignment={assignmentInfo} student={studentInfo} />}
      <EditContainer isTimedAssignment={propsTimed.isTimedAssignment} />
    </>
  );
};
