/* istanbul ignore file */
import axios from 'axios';
import {
  IAssignmentsData,
  IHeaderAssignmentInfo,
  IHeaderStudentInfo,
  IStudentInfo,
  RestrictionLevel,
} from 'context/ExtensionsContextProvider/types';
import { convertToBoolean } from 'utils/utils';

const instance = axios.create({
  baseURL: window.location.origin,
});

const getHeaderAssignmentInfo = (
  deploymentId: string
): Promise<{ data: { result: IHeaderAssignmentInfo; status: number } }> => {
  return instance
    .get(`/web/Rest/Faculty-Extensions/header_assignment_info?deploymentId=${deploymentId}`)
    .then(response => {
      const assignmentInfo: IHeaderAssignmentInfo = {
        ...response.data.result,
        assignmentTimed: convertToBoolean(response.data.result.assignmentTimed),
      };

      return {
        data: {
          result: assignmentInfo,
          status: response.status,
        },
      };
    });
};

const getHeaderStudentInfo = (
  studentId: string,
  restrictionLevel: RestrictionLevel,
  id: string
): Promise<{
  data: { result: IHeaderStudentInfo; status: number };
}> => {
  return instance
    .get(
      `/web/Rest/Faculty-Extensions/header_student_info?studentId=${studentId}&restrictionLevel=${restrictionLevel}&id=${id}`
    )
    .then(response => {
      const studentInfo: IHeaderStudentInfo = {
        ...response.data.result,
        studentHasSeenKeys: convertToBoolean(response.data.result.studentHasSeenKeys),
      };

      return {
        data: {
          result: studentInfo,
          status: response.status,
        },
      };
    });
};

const getAssignmentsInSection = (
  sectionId: string
): Promise<{
  data: { result: IAssignmentsData; status: number };
}> => {
  return instance.get(`/web/Rest/Faculty-Extensions/assignments_in_section?sectionId=${sectionId}`);
};

const getStudentsInSection = (
  sectionId: string
): Promise<{
  data: { result: IStudentInfo[]; status: number };
}> => {
  return instance.get(`/web/Rest/Faculty-Extensions/students_in_section?sectionId=${sectionId}`);
};

export { getHeaderAssignmentInfo, getHeaderStudentInfo, getAssignmentsInSection, getStudentsInSection };
