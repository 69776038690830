import React from 'react';
import { Flex, FlexAlignItems, FlexBehavior, Input, InputType } from 'react-magma-dom';
import { InfoIcon } from 'react-magma-icons';
import { Theme } from 'wa-ui-components';
import { useTranslation } from 'react-i18next';

const inputContainerStyle: React.CSSProperties = { width: '8rem', whiteSpace: 'nowrap', boxSizing: 'border-box' };

interface IAdditionalSubmissionsProps {
  submissions?: number;
  setNumberOfSubmissions: (submissions?: number) => void;
}

export const AdditionalSubmissionsStub = (props: IAdditionalSubmissionsProps): JSX.Element => {
  const { t } = useTranslation();
  const { submissions, setNumberOfSubmissions } = props;

  const handleNumberOfSubmissionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const parsedValue = value ? Number(value) : undefined;
    setNumberOfSubmissions(parsedValue);
  };

  const numberOfSubmissionsStub = 5;

  return (
    <Flex behavior={FlexBehavior.container} spacing={1} alignItems={FlexAlignItems.center}>
      <Input
        labelText={t('EXTENSIONS.ADD_SUBMISSIONS_LABEL')}
        helperMessage={t('EXTENSIONS.ADD_SUBMISSIONS_HELPER_MESSAGE', { numberOfSubmissions: numberOfSubmissionsStub })}
        value={submissions ?? ''}
        onChange={handleNumberOfSubmissionsChange}
        type={InputType.number}
        containerStyle={inputContainerStyle}
      />
      <InfoIcon color={Theme.colors.primaryBlue} />
    </Flex>
  );
};
