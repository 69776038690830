import styled from '@emotion/styled';
import React from 'react';
import { EditExtensions } from 'facultyComponents/extensions/edit/EditExtensions';
import { StudentLog } from 'facultyComponents/extensions/edit/StudentLog';

const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem; // Adjust the gap between the components
  align-items: stretch; // Ensures both components stretch to the same height
  margin: 0 auto; // Center the container
`;

interface IEditExtensionsProps {
  isTimedAssignment: boolean;
}

export const EditContainer: React.FC<IEditExtensionsProps> = (props: IEditExtensionsProps) => {
  const { isTimedAssignment } = props;
  return (
    <Container>
      <EditExtensions isTimedAssignment={isTimedAssignment} />
      <StudentLog
        points={undefined}
        maxPoints={10}
        availableDate="12/7/2024 9:30 PM EST"
        downloaded={false}
        lastSubmission={null}
      />
    </Container>
  );
};
