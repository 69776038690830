import React from 'react';
import { Heading, magma, Spacer, TypographyContextVariant, TypographyVisualStyle } from 'react-magma-dom';
import { HeaderAssignmentInfoRestrictions } from './AssignmentInfoRestrictions';
import { IHeaderAssignmentInfo } from 'context/ExtensionsContextProvider/types';

type HeaderAssignmentInfoProps = {
  assignment: IHeaderAssignmentInfo;
  sectionId: string;
};

export const HeaderAssignmentInfo: React.FC<HeaderAssignmentInfoProps> = (props: HeaderAssignmentInfoProps) => {
  const { assignment, sectionId } = props;

  return (
    <>
      <Spacer size={magma.spaceScale.spacing04} />
      <Heading
        level={2}
        noMargins
        contextVariant={TypographyContextVariant.expressive}
        visualStyle={TypographyVisualStyle.headingSmall}
        style={{ color: magma.colors.neutral }}
        css
      >
        {assignment.assignmentName} ({assignment.assignmentId})
      </Heading>
      {assignment.assignmentRestrictions && (
        <>
          <Spacer size={magma.spaceScale.spacing05} />
          <HeaderAssignmentInfoRestrictions assignment={assignment} sectionId={sectionId} />
        </>
      )}
    </>
  );
};
