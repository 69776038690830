import React, { useEffect, useState } from 'react';
import {
  ButtonColor,
  ButtonSize,
  Dropdown,
  DropdownAlignment,
  DropdownButton,
  DropdownContent,
  DropdownDropDirection,
  DropdownMenuItem,
} from 'react-magma-dom';
import { IStudentInfo } from 'context/ExtensionsContextProvider/types';
import { getStudentsInSection } from '../../../../pages/extensions/apiHelpers';
import { useHistory } from 'react-router-dom';

type StudentDropDownProps = {
  sectionId: string;
  currentStudentId: string;
};

const removeCurrentStudent = (students: IStudentInfo[], id: string): IStudentInfo[] => {
  return students.filter(student => student.studentId !== id);
};

export const StudentDropDown: React.FC<StudentDropDownProps> = (props: StudentDropDownProps) => {
  const { sectionId, currentStudentId } = props;
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [students, setStudents] = useState<IStudentInfo[]>([]);

  useEffect(() => {
    if (!sectionId) return;
    const fetchAssignmentsData = async () => {
      try {
        const response = await getStudentsInSection(sectionId);
        setStudents(removeCurrentStudent(response.data.result, currentStudentId));
      } catch (error) {
        console.error('Failed to fetch students data:', error);
      }
    };
    fetchAssignmentsData().finally(() => {
      setLoading(false);
    });
  }, [sectionId, currentStudentId]);

  const handleStudentClick = (userId: string, sectionId: string) => {
    setLoading(true);
    history.push(`/app/extensions/user/${userId}/section/${sectionId}`);
  };

  return (
    <>
      <Dropdown alignment={DropdownAlignment.end} dropDirection={DropdownDropDirection.down}>
        <DropdownButton
          aria-label={'Change Student'}
          color={ButtonColor.secondary}
          size={ButtonSize.medium}
          disabled={loading}
        >
          Change Student
        </DropdownButton>
        <DropdownContent>
          {students.map(student => (
            <DropdownMenuItem key={student.studentId} onClick={() => handleStudentClick(student.studentId, sectionId)}>
              {student.studentFullname}
            </DropdownMenuItem>
          ))}
        </DropdownContent>
      </Dropdown>
    </>
  );
};
