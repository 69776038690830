import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { IHeaderAssignmentInfo } from 'context/ExtensionsContextProvider/types';
import { getHeaderAssignmentInfo } from './apiHelpers';
import { Header } from 'facultyComponents/extensions/header/Header';
import { LoaderComponent } from 'wa-ui-components';

export const AssignmentStudentsPage: React.FC = () => {
  const { deploymentId, sectionId } = useParams<{ deploymentId: string; sectionId: string }>();

  const [isHeaderLoading, setHeaderLoading] = useState(true);
  const [assignmentInfo, setAssignmentInfo] = useState<IHeaderAssignmentInfo>();

  useEffect(() => {
    if (!deploymentId) return;
    const fetchAssignmentInfo = async () => {
      try {
        const response = await getHeaderAssignmentInfo(deploymentId);
        setAssignmentInfo(response.data.result);
      } catch (error) {
        console.error('Failed to fetch assignment restrictions:', error);
      }
    };

    fetchAssignmentInfo().finally(() => {
      setHeaderLoading(false);
    });
  }, [deploymentId]);

  return <>{isHeaderLoading ? <LoaderComponent /> : <Header assignment={assignmentInfo} sectionId={sectionId} />}</>;
};
