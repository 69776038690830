import React from 'react';
import { Flex, FlexAlignItems, FlexBehavior, FlexJustify, FlexWrap, Hyperlink, magma } from 'react-magma-dom';
import { AccessTimeIcon } from 'react-magma-icons';
import styled from '@emotion/styled';
import { IHeaderAssignmentInfo } from 'context/ExtensionsContextProvider/types';

type HeaderAssignmentInfoRestrictionsProps = {
  assignment: IHeaderAssignmentInfo;
  sectionId?: string;
};

const LinkWrapper = styled.div`
  display: inline-block;

  a {
    color: ${({ theme }) => theme.colors.primaryBlue};
    background-color: transparent;
    border: none;
    font: 1rem ${({ theme }) => theme.OpenSans};
    font-weight: 400;
    text-decoration: none;
    cursor: pointer;
  }
`;

export const HeaderAssignmentInfoRestrictions: React.FC<HeaderAssignmentInfoRestrictionsProps> = (
  props: HeaderAssignmentInfoRestrictionsProps
) => {
  const { assignment, sectionId } = props;

  return (
    <>
      <LinkWrapper>
        <Hyperlink
          target="_blank"
          to={`/web/Faculty/Assignment-Schedule/schedule?aid=${assignment.assignmentId}&section=${sectionId}`}
        >
          <Flex
            behavior={FlexBehavior.container}
            alignItems={FlexAlignItems.center}
            justify={FlexJustify.flexStart}
            spacing={0.5}
            wrap={FlexWrap.nowrap}
          >
            {assignment.assignmentTimed && (
              <Flex behavior={FlexBehavior.item}>
                <AccessTimeIcon size={24} color={magma.colors.primary} style={{ display: 'block' }} />
              </Flex>
            )}
            <Flex behavior={FlexBehavior.item}>{assignment.assignmentRestrictions}</Flex>
          </Flex>
        </Hyperlink>
      </LinkWrapper>
    </>
  );
};
