import React from 'react';
import { Flex, FlexBehavior, Input, InputType, Select } from 'react-magma-dom';
import { useTranslation } from 'react-i18next';

const selectContainerStyle: React.CSSProperties = { width: '12.5rem' };
const selectLabelStyle: React.CSSProperties = { paddingBottom: '1px' };
const inputContainerStyle: React.CSSProperties = { width: '6rem' };

interface IPenaltiesProps {
  penaltyOption?: string;
  penaltyValue?: number;
  setPenaltyOption: (option?: string) => void;
  setPenaltyValue: (value?: number) => void;
}

export const PenaltiesStub = (props: IPenaltiesProps): JSX.Element => {
  const { penaltyOption, penaltyValue, setPenaltyOption, setPenaltyValue } = props;
  const { t } = useTranslation();

  const selectOptions: string[] = [
    t('EXTENSIONS.PENALTY_UNEARNED_OPTION'),
    t('EXTENSIONS.PENALTY_TOTAL_OPTION'),
    t('EXTENSIONS.PENALTY_POINTS_OPTION'),
  ];

  const isPointsOptionSelected = penaltyOption === t('EXTENSIONS.PENALTY_POINTS_OPTION');

  const handleSelectedPenaltyChange = (changes: any) => {
    const selectedValue = changes.selectedItem ?? '';
    setPenaltyOption(selectedValue);
  };

  const handlePenaltyValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const parsedValue = value ? Number(value) : undefined;
    setPenaltyValue(parsedValue);
  };

  return (
    <Flex behavior={FlexBehavior.container} spacing={1}>
      <Select
        labelText={t('EXTENSIONS.PENALTY_LABEL')}
        items={selectOptions}
        placeholder={selectOptions[0]}
        selectedItem={penaltyOption}
        onSelectedItemChange={handleSelectedPenaltyChange}
        containerStyle={selectContainerStyle}
        labelStyle={selectLabelStyle}
      />
      <Input
        labelText={
          isPointsOptionSelected ? t('EXTENSIONS.PENALTY_POINTS_LABEL') : t('EXTENSIONS.PENALTY_PERCENTAGE_LABEL')
        }
        value={penaltyValue ?? ''}
        onChange={handlePenaltyValueChange}
        type={InputType.number}
        containerStyle={inputContainerStyle}
      />
    </Flex>
  );
};
