import React, { useEffect, useState } from 'react';
import { Input, InputType, magma, Paragraph, TypographyContextVariant, TypographyVisualStyle } from 'react-magma-dom';
import { MoreTimeIcon } from 'react-magma-icons';
import { useTranslation } from 'react-i18next';

import {
  allowOnlyDecimalInput,
  getDueDateTimeFromTimeRemaining,
  validateTimeAccommodationInput,
} from 'utils/validation/validationHelpers';
import styled from '@emotion/styled';
import { BulkEditType, EditType } from 'context/ExtensionsContextProvider/types';

const StyledContainer = styled.div<{ editType: EditType }>`
  min-width: 11rem;
  padding-top: ${({ editType }) => (editType === EditType.EDIT ? '2px' : '')};

  [data-testid='inputMessage'] {
    white-space: pre-line;
    min-width: max-content;
    align-items: start;
  }
`;

const inputContainerStyle: React.CSSProperties = { width: '8rem' };
const inputStyle: React.CSSProperties = { zIndex: 0 };
const titleStyleWhenUnlimitedTimeAccommodationApplied: React.CSSProperties = {
  fontWeight: 600,
  color: magma.colors.neutral,
  margin: '0 0 4px 0',
};
const contentStyleWhenUnlimitedTimeAccommodationApplied: React.CSSProperties = {
  color: magma.colors.neutral03,
  whiteSpace: 'pre-line',
};

interface IAdditionalTimeProps {
  timeAccommodation?: number;
  isUnlimitedTimeAccommodation?: boolean;
  baseTime?: number;
  studentTimeRemaining?: number;
  editType: EditType;
  bulkEditType?: BulkEditType;
  onChange: (timeAccommodation?: number) => void;
}

const initialValidationState = {
  error: false,
  message: '',
};

export const AdditionalTime = (props: IAdditionalTimeProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    timeAccommodation,
    isUnlimitedTimeAccommodation,
    onChange,
    editType,
    bulkEditType,
    baseTime,
    studentTimeRemaining,
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
  const [unsavedStudentTimeRemaining, setUnsavedStudentTimeRemaining] = useState(studentTimeRemaining);
  const [validationError, setValidationError] = useState(initialValidationState);

  useEffect(() => {
    if (timeAccommodation === undefined) {
      setValidationError(initialValidationState);
      setIsUnsavedChanges(false);
      setUnsavedStudentTimeRemaining(studentTimeRemaining);
    }
  }, [timeAccommodation]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setValidationError(validateTimeAccommodationInput(value));

    const parsedValue = value ? Number(value) : undefined;
    onChange(parsedValue);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (timeAccommodation && studentTimeRemaining && !validationError.error) {
      setIsUnsavedChanges(true);
      setUnsavedStudentTimeRemaining(studentTimeRemaining + timeAccommodation);
    }
  };

  const setHelperMessage = (): string => {
    let helperMessage = '';

    if (editType === EditType.EDIT) {
      helperMessage = t('EXTENSIONS.ADD_TIME.HELPER_MESSAGE.EDIT_VIEW.DEFAULT', {
        baseTime: baseTime,
        studentTimeRemaining: unsavedStudentTimeRemaining,
      });
      if (isUnsavedChanges) {
        helperMessage = t('EXTENSIONS.ADD_TIME.HELPER_MESSAGE.EDIT_VIEW.UNSAVED_CHANGES', {
          baseTime: baseTime,
          studentTimeRemaining: unsavedStudentTimeRemaining,
        });
      }
    } else if (editType === EditType.BULK_EDIT) {
      if (bulkEditType === BulkEditType.ASSIGNMENT) {
        helperMessage = t('EXTENSIONS.ADD_TIME.HELPER_MESSAGE.BULK_EDIT_VIEW.ASSIGNMENTS.DEFAULT');
        if (isFocused) {
          helperMessage = t('EXTENSIONS.ADD_TIME.HELPER_MESSAGE.BULK_EDIT_VIEW.ASSIGNMENTS.FOCUSED');
        }
        if (isUnsavedChanges) {
          helperMessage = t('EXTENSIONS.ADD_TIME.HELPER_MESSAGE.BULK_EDIT_VIEW.ASSIGNMENTS.UNSAVED_CHANGES');
        }
      } else if (bulkEditType === BulkEditType.STUDENT) {
        helperMessage = t('EXTENSIONS.ADD_TIME.HELPER_MESSAGE.BULK_EDIT_VIEW.STUDENTS.DEFAULT', { baseTime: baseTime });
        if (isFocused) {
          helperMessage = t('EXTENSIONS.ADD_TIME.HELPER_MESSAGE.BULK_EDIT_VIEW.STUDENTS.FOCUSED', {
            baseTime: baseTime,
          });
        }
        if (isUnsavedChanges) {
          helperMessage = t('EXTENSIONS.ADD_TIME.HELPER_MESSAGE.BULK_EDIT_VIEW.STUDENTS.UNSAVED_CHANGES', {
            baseTime: baseTime,
          });
        }
      }
    }
    return helperMessage;
  };

  const showUnlimitedTimeAccommodationMessage = () => {
    return isUnlimitedTimeAccommodation && (editType === EditType.EDIT || bulkEditType === BulkEditType.ASSIGNMENT);
  };

  return (
    <StyledContainer editType={editType}>
      {showUnlimitedTimeAccommodationMessage() ? (
        <>
          <Paragraph
            contextVariant={TypographyContextVariant.expressive}
            visualStyle={TypographyVisualStyle.bodySmall}
            style={titleStyleWhenUnlimitedTimeAccommodationApplied}
          >
            {t('EXTENSIONS.ADD_TIME.LABEL')}
          </Paragraph>
          <Paragraph
            noMargins
            contextVariant={TypographyContextVariant.expressive}
            visualStyle={TypographyVisualStyle.bodySmall}
            style={contentStyleWhenUnlimitedTimeAccommodationApplied}
          >
            {t('EXTENSIONS.ADD_TIME.HELPER_MESSAGE.UNLIMITED_TIME_ACCOMMODATION', {
              dueDateTime: getDueDateTimeFromTimeRemaining(studentTimeRemaining),
            })}
          </Paragraph>
        </>
      ) : (
        <Input
          testId={'addTimeField'}
          labelText={t('EXTENSIONS.ADD_TIME.LABEL')}
          helperMessage={setHelperMessage()}
          onFocus={handleFocus}
          onBeforeInput={allowOnlyDecimalInput}
          onChange={handleChange}
          onBlur={handleBlur}
          type={InputType.number}
          value={timeAccommodation ?? ''}
          min={1}
          max={1000}
          icon={<MoreTimeIcon />}
          containerStyle={inputContainerStyle}
          inputStyle={inputStyle}
          errorMessage={validationError.error ? validationError.message : null}
        />
      )}
    </StyledContainer>
  );
};
