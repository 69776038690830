import styled from '@emotion/styled';
import { Card, Flex, FlexAlignItems, FlexBehavior, FlexJustify, FlexWrap, Hyperlink } from 'react-magma-dom';
import React from 'react';
import { ExternalLinkIcon } from 'react-magma-icons';

const StudentLogCard = styled(Card)`
  padding: 1.5rem;
  border-radius: 0.25rem;
  background: ${({ theme }) => theme.colors.lightGrey};
  box-sizing: border-box;
  margin-right: 2em;
`;

const LogTitle = styled.h5`
  margin: 0 0 1.2rem 0;
  font-size: 1rem;
`;

const LogItem = styled.p`
  margin: 0.5rem 0;
  font-size: 0.875rem;
  flex: 1;
`;

const LinkWrapper = styled.div`
  display: inline-block;
  margin-top: 0.5rem;

  a {
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-size: 0.875rem;
    background-color: transparent;
    border: none;
    font: ${({ theme }) => theme.OpenSans};
    font-weight: 400;
    text-decoration: none;
    cursor: pointer;
  }
`;

interface StudentLogProps {
  points?: number;
  maxPoints?: number;
  availableDate: string;
  downloaded: boolean;
  lastSubmission: string | null;
}

export const StudentLog = (props: StudentLogProps) => {
  const { points, maxPoints, availableDate, downloaded, lastSubmission } = props;

  return (
    <StudentLogCard>
      <LogTitle>Student Log</LogTitle>
      {points && (
        <LogItem>
          <strong>Points:</strong> <br />
          {points} of {maxPoints} pts
        </LogItem>
      )}
      <LogItem>
        <strong>Available Date:</strong> <br /> {availableDate}
      </LogItem>
      <LogItem>
        <strong>Downloaded:</strong> <br />
        {downloaded ? 'Downloaded' : 'Never Downloaded'}
      </LogItem>
      <LogItem>
        <strong>Last Submission:</strong> <br />
        {lastSubmission ? lastSubmission : 'Never Submitted'}
      </LogItem>
      <LinkWrapper>
        <Hyperlink target="_blank" to="https://jira.cengage.com/browse/WAPLAT-36497">
          <Flex
            behavior={FlexBehavior.container}
            alignItems={FlexAlignItems.center} // Align items vertically in the center
            justify={FlexJustify.flexStart}
            spacing={0.5} // Add spacing between text and icon
            wrap={FlexWrap.nowrap}
          >
            <Flex behavior={FlexBehavior.item}>More Log Info</Flex>
            <Flex behavior={FlexBehavior.item}>
              <ExternalLinkIcon size={18} style={{ verticalAlign: 'middle' }} /> {/* Align icon vertically */}
            </Flex>
          </Flex>
        </Hyperlink>
      </LinkWrapper>

      <LinkWrapper>
        <Hyperlink target="_blank" to="https://wiki.cengage.com/pages/viewpage.action?pageId=355441824">
          <Flex
            behavior={FlexBehavior.container}
            alignItems={FlexAlignItems.center} // Align items vertically in the center
            justify={FlexJustify.flexStart}
            spacing={0.5} // Add spacing between text and icon
            wrap={FlexWrap.nowrap}
          >
            <Flex behavior={FlexBehavior.item}>Student Responses</Flex>
            <Flex behavior={FlexBehavior.item}>
              <ExternalLinkIcon size={18} style={{ verticalAlign: 'middle' }} /> {/* Align icon vertically */}
            </Flex>
          </Flex>
        </Hyperlink>
      </LinkWrapper>
    </StudentLogCard>
  );
};
