import React, { ReactElement } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import styled from '@emotion/styled';
import FacultyOmniBar from 'facultyComponents/facultyHeader/FacultyOmniBar';
import { StudentFooter as FacultyFooter } from 'wa-ui-components';
import MasqueradeHeader from '../../components/masqueradeHeader/MasqueradeHeader';
import { LoaderComponent } from 'wa-ui-components';
import { useFetchedDataContext } from 'context/data/FetchedData';
import { ACTIONS, ActionType, getLoadingAction } from 'context/data/actionCreators';
import { API_NAME_CONSTANTS } from '../../service/registry';
import { useDirectorAPI } from '../../service/dataHooks';
import { DirectorSetting, DirectorSettings } from 'context/sharedTypes';
import { BackToClassLink } from 'facultyComponents/backToClass/BackToClassLink';
import { AssignmentStudentsPage } from './AssignmentStudentsPage';
import { StudentAssignmentPage } from './StudentAssignmentPage';
import { StudentAssignmentsPage } from './StudentAssignmentsPage';

const FacultyMain = styled.main`
  box-sizing: border-box;
  min-height: calc(100vh - 8.75rem);
`;

const ExtensionTopBlock = styled.div`
  margin-left: 3px;
`;

export const Root = (): ReactElement => {
  const { data, status: directorStatus } = useDirectorAPI({
    settingName: DirectorSettings.IS_TIME_ACCOMMODATION_ENABLED,
  });
  const directorSetting: DirectorSetting<boolean> = data;
  const isTimeAccommodationEnabled = directorStatus === ACTIONS.LOADED && directorSetting.value;

  const { getter } = useFetchedDataContext();
  const apiDataState: ActionType | null = getter(API_NAME_CONSTANTS.USER);
  const { data: userData, status: userStatus } = apiDataState
    ? apiDataState
    : getLoadingAction(API_NAME_CONSTANTS.USER);

  if (userStatus === ACTIONS.LOADING) {
    return <LoaderComponent />;
  }

  return (
    <BrowserRouter>
      {userData.masqueraded && (
        <MasqueradeHeader fullName={userData.fullName} userName={userData.userName} schoolName={userData.schoolName} />
      )}
      <FacultyOmniBar userData={userData} userStatus={userStatus} />
      <ExtensionTopBlock>
        <BackToClassLink classContext={userData.activeClassContext} spacer />
      </ExtensionTopBlock>
      <FacultyMain>
        {isTimeAccommodationEnabled && (
          <Switch>
            <Route
              exact
              path="/app/extensions/user/:userId/deployment/:deploymentId"
              render={() => <StudentAssignmentPage />}
            />
            <Route
              exact
              path="/app/extensions/deployment/:deploymentId/section/:sectionId"
              render={() => <AssignmentStudentsPage />}
            />
            <Route
              exact
              path="/app/extensions/user/:userId/section/:sectionId"
              render={() => <StudentAssignmentsPage />}
            />
          </Switch>
        )}
      </FacultyMain>
      <FacultyFooter disableLink="0" />
    </BrowserRouter>
  );
};
