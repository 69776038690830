import React from 'react';
import {
  Card,
  Flex,
  FlexAlignItems,
  FlexBehavior,
  FlexJustify,
  FlexWrap,
  Heading,
  magma,
  Spacer,
  TypographyContextVariant,
  TypographyVisualStyle,
} from 'react-magma-dom';
import { HeaderAssignmentInfo } from './AssignmentInfo';
import { HeaderStudentInfo } from './StudentInfo';
import { StudentDropDown } from './StudentDropDown';
import { AssignmentDropDown } from './AssignmentDropDown';
import styled from '@emotion/styled';
import { IHeaderAssignmentInfo, IHeaderStudentInfo } from 'context/ExtensionsContextProvider/types';

type HeaderProps = {
  assignment?: IHeaderAssignmentInfo;
  student?: IHeaderStudentInfo;
  sectionId?: string;
};

const HeaderMain = styled.div`
  margin-left: 16px;
  margin-right: 16px;
`;

const HeaderMain2 = styled.div`
  margin-left: 24px;
  margin-right: 24px;
`;

const hasDropdown = (assignment: IHeaderAssignmentInfo | undefined, student: IHeaderStudentInfo | undefined) => {
  return (assignment && !student) || (!assignment && student);
};

export const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { assignment, student } = props;
  const sectionId = props.sectionId ?? props.assignment?.assignmentSectionId ?? '';

  return (
    <>
      <HeaderMain>
        {hasDropdown(assignment, student) ? (
          <Spacer size={magma.spaceScale.spacing03} />
        ) : (
          <Spacer size={magma.spaceScale.spacing05} />
        )}
        <Flex
          behavior={FlexBehavior.container}
          alignItems={FlexAlignItems.center}
          justify={FlexJustify.spaceBetween}
          spacing={2}
          wrap={FlexWrap.nowrap}
        >
          <Flex behavior={FlexBehavior.item}>
            <Heading
              level={1}
              noMargins
              contextVariant={TypographyContextVariant.default}
              visualStyle={TypographyVisualStyle.headingMedium}
              css
            >
              Extensions / Accommodations
            </Heading>
          </Flex>
          <Flex behavior={FlexBehavior.item}>
            {assignment && !student && (
              <AssignmentDropDown sectionId={sectionId} currentAssignmentId={assignment.assignmentId} />
            )}
            {!assignment && student && <StudentDropDown sectionId={sectionId} currentStudentId={student.studentId} />}
          </Flex>
        </Flex>
        {assignment && <HeaderAssignmentInfo assignment={assignment} sectionId={sectionId} />}
        {!assignment && student && <HeaderStudentInfo student={student} />}
        <Spacer size={magma.spaceScale.spacing05} />
      </HeaderMain>
      <Card></Card>
      {assignment && student && (
        <HeaderMain2>
          <HeaderStudentInfo indent student={student} />
        </HeaderMain2>
      )}
      <Spacer size={magma.spaceScale.spacing05} />
    </>
  );
};
