import React from 'react';
import {
  Flex,
  FlexAlignItems,
  FlexBehavior,
  FlexJustify,
  FlexWrap,
  Heading,
  magma,
  Paragraph,
  Spacer,
  Tag,
  TagColor,
  TagSize,
  TypographyContextVariant,
  TypographyVisualStyle,
} from 'react-magma-dom';
import { AccessTimeIcon } from 'react-magma-icons';
import { IHeaderStudentInfo } from 'context/ExtensionsContextProvider/types';

type HeaderStudentInfoProps = {
  indent?: boolean;
  student: IHeaderStudentInfo;
};

export const HeaderStudentInfo: React.FC<HeaderStudentInfoProps> = (props: HeaderStudentInfoProps) => {
  const { indent = false, student } = props;

  let multiplier;

  switch (student.studentMultiplier) {
    case 'DOUBLE':
      multiplier = '2.0x time limit accommodation';
      break;
    case 'TIME_AND_ONE_HALF':
      multiplier = '1.5x time limit accommodation';
      break;
    case 'UNLIMITED':
      multiplier = 'Unlimited time until due date accommodation';
      break;
    default:
      multiplier = '';
  }

  return (
    <>
      {indent ? <Spacer size={magma.spaceScale.spacing05} /> : <Spacer size={magma.spaceScale.spacing03} />}
      <Flex
        behavior={FlexBehavior.container}
        alignItems={FlexAlignItems.center}
        justify={FlexJustify.flexStart}
        spacing={3}
        wrap={FlexWrap.nowrap}
      >
        <Flex behavior={FlexBehavior.item}>
          <Heading
            level={3}
            noMargins
            contextVariant={TypographyContextVariant.expressive}
            visualStyle={TypographyVisualStyle.headingSmall}
            style={{ color: magma.colors.neutral }}
            css
          >
            {student.studentFullname}
          </Heading>
        </Flex>
        {student.studentHasSeenKeys && (
          <Flex behavior={FlexBehavior.item}>
            <Tag color={TagColor.primary} size={TagSize.small}>
              Shown Answer Key
            </Tag>
          </Flex>
        )}
        {multiplier && (
          <Flex behavior={FlexBehavior.item}>
            <Flex
              behavior={FlexBehavior.container}
              alignItems={FlexAlignItems.center}
              justify={FlexJustify.flexStart}
              spacing={0.5}
              wrap={FlexWrap.nowrap}
            >
              <Flex behavior={FlexBehavior.item}>
                <AccessTimeIcon size={24} color={magma.colors.neutral} style={{ display: 'block' }} />
              </Flex>
              <Flex behavior={FlexBehavior.item}>
                <Paragraph
                  noMargins
                  contextVariant={TypographyContextVariant.expressive}
                  visualStyle={TypographyVisualStyle.bodyMedium}
                  style={{ color: magma.colors.neutral }}
                >
                  {multiplier}
                </Paragraph>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
    </>
  );
};
