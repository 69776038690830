import i18next from '../../i18n';
import { ITranslationItem } from 'context/AssignmentContextProvider/types';

export const getApplicationHostName = (): string => {
  const hostName =
    window.location.hostname.indexOf('www.') === 0
      ? window.location.hostname.replace('www.', '')
      : window.location.hostname;

  let url = '';

  switch (hostName) {
    case 'webassign.net':
    case 'webassign.com':
    case 'assets.webassign.net':
      url = 'https://www.webassign.net';
      break;
    case 'demo.webassign.net':
    case 'assets-demo.webassign.net':
      url = 'https://demo.webassign.net';
      break;
    case 'editorial.webassign.net':
    case 'assets-editorial.webassign.net':
      url = 'https://editorial.webassign.net';
      break;
    case 'wa-perf.net':
    case 'assets.wa-perf.net':
      url = 'https://www.wa-perf.net';
      break;
    case 'wa-staging.net':
    case 'assets.wa-staging.net':
      url = 'https://www.wa-staging.net';
      break;
    case 'integration.webassign.net':
    case 'assets-int.webassign.net':
      url = 'https://integration.webassign.net';
      break;
    default:
      url = window.location.origin;
  }

  return url;
};

export const applicationHostName = getApplicationHostName();

export const translateSelectOptions = (selectOptions: ITranslationItem[]): ITranslationItem[] => {
  return selectOptions.map((option: ITranslationItem) => {
    return { label: i18next.t(option.label), value: option.value };
  });
};

/*Creates an object (based on passed in object) with new or updated field.
Field added according to passed path in format a.b.z.
If passed value is undefined - removes the specified field from the object*/
export const addFieldToObj = (obj: any, path: string, value: any): any => {
  const res: any = { ...obj };
  let resVal: any = res;
  let i = 0;
  const pathArray = path.split('.');

  for (; i < pathArray.length - 1; i++) {
    const currentPath: string = pathArray[i];
    const currentObj: any = getFieldValue(obj, pathArray.slice(0, i + 1).join('.'));

    if (currentObj && isObject(currentObj)) {
      resVal = resVal[currentPath] = { ...currentObj };
    } else {
      resVal = resVal[currentPath] = {};
    }
  }

  if ((i === 0 ? obj : resVal)[pathArray[i]] === value) {
    return obj;
  }

  if (value === undefined) {
    delete resVal[pathArray[i]];
  } else {
    resVal[pathArray[i]] = value;
  }

  if (i === 0 && value === undefined) {
    delete res[pathArray[i]];
  }

  return res;
};

export const getFieldValue = (obj: any, key: string, def?: any, p = 0) => {
  const path = key.split('.');
  while (obj && p < path.length) {
    obj = obj[path[p++]];
  }
  return obj === undefined ? def : obj;
};

export const isObject = (obj: any): boolean => obj !== null && typeof obj === 'object' && !Array.isArray(obj);

/**
 * Converts a given value to a boolean.
 *
 * This function handles inputs of type `string`, `number`, or `boolean`. It converts:
 * - `1` (number or string) to `true`.
 * - `0` (number or string) to `false`.
 * - The string `"true"` (case-insensitive) to `true`.
 * - The string `"false"` (case-insensitive) to `false`.
 * - Boolean inputs are returned as-is.
 * - Invalid inputs (e.g., non-numeric strings like `"abc"`) result in a warning and return `false`.
 *
 * @param {string | number | boolean} value - The value to convert to a boolean.
 * @returns {boolean} - The converted boolean value.
 *
 * @example
 * // Numeric inputs
 * convertToBoolean(1); // true
 * convertToBoolean(0); // false
 * convertToBoolean("1"); // true
 * convertToBoolean("0"); // false
 *
 * @example
 * // String inputs
 * convertToBoolean("true"); // true
 * convertToBoolean("false"); // false
 * convertToBoolean("True"); // true (case-insensitive)
 * convertToBoolean("FALSE"); // false (case-insensitive)
 *
 * @example
 * // Boolean inputs
 * convertToBoolean(true); // true
 * convertToBoolean(false); // false
 *
 * @example
 * // Invalid inputs
 * convertToBoolean("abc"); // false (with warning)
 * convertToBoolean(NaN); // false (with warning)
 */
export const convertToBoolean = (value: string | number | boolean): boolean => {
  if (typeof value === 'boolean') {
    return value;
  }
  const numericValue = Number(value);
  if (isNaN(numericValue)) {
    if (typeof value === 'string') {
      const lowerCaseValue = value.toLowerCase();
      if (lowerCaseValue === 'true') return true;
      if (lowerCaseValue === 'false') return false;
    }
    console.warn(`Invalid value for conversion to boolean: ${value}. Defaulting to false.`);
    return false;
  }
  return numericValue === 1;
};
