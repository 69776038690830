import React, { useEffect, useRef, useState } from 'react';
import { DatePicker, Flex, FlexBehavior, magma, TimePicker } from 'react-magma-dom';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import {
  allowOnlyDecimalInput,
  allowOnlyPatternInput,
  getDateWithoutTime,
  getDateWithTimezoneOffset,
  getFormattedDate,
  isValidDateFormat,
  isValidTimeFormat,
  validateExtendedDueDateInputRange,
} from 'utils/validation/validationHelpers';

const StyledTimePicker = styled(TimePicker)<{ isInErrorState: boolean }>`
  & > div {
    height: 2.625rem;
    border-color: ${({ isInErrorState }) => (isInErrorState ? magma.colors.danger : magma.colors.neutral03)};
    box-shadow: ${({ isInErrorState }) => (isInErrorState ? `0 0 0 1px ${magma.colors.danger}` : '')};
  }
`;
const datePickerContainerStyle: React.CSSProperties = { width: '12rem', whiteSpace: 'nowrap', boxSizing: 'border-box' };
const timePickerContainerStyle: React.CSSProperties = {
  width: '11rem',
  boxSizing: 'border-box',
};

interface IExtendedDueDateProps {
  classDueDateTime: string;
  studentExtendedDueDate?: Date;
  studentExtendedDueTime?: string;
  onChangeStudentExtendedDueDate: (extendedDueDate?: Date) => void;
  onChangeStudentExtendedDueTime: (extendedDueTime?: string) => void;
  onReset: boolean;
}

const initialValidationState = {
  error: false,
  message: '',
};

export const ExtendedDueDate = (props: IExtendedDueDateProps): JSX.Element => {
  const {
    classDueDateTime,
    studentExtendedDueDate,
    studentExtendedDueTime,
    onChangeStudentExtendedDueDate,
    onChangeStudentExtendedDueTime,
    onReset,
  } = props;

  const { t } = useTranslation();
  const [extendedDueDateKey, setExtendedDueDateKey] = useState(0);
  const [extendedDueTimeKey, setExtendedDueTimeKey] = useState(false);
  const [extendedDueDateValidationError, setExtendedDueDateValidationError] = useState(initialValidationState);
  const [extendedDueDateTimeValidationError, setExtendedDueDateTimeValidationError] = useState(initialValidationState);
  const extendedDueDateInputRef = useRef<HTMLInputElement | null>(null);
  const parsedClassDueDateTimeInLocale = getDateWithTimezoneOffset(classDueDateTime);

  const handleExtendedDueDateChange = (value?: Date) => {
    onChangeStudentExtendedDueDate(value);
  };

  const handleExtendedDueDateInputChange = (event: React.ChangeEvent): void => {
    const value = (event.target as HTMLInputElement).value;
    if (value === '') {
      resetErrorStates();
    } else if (isValidDateFormat(value)) {
      onChangeStudentExtendedDueDate(new Date(value));
    }
  };

  const handleExtendedDueDateBlur = () => {
    const value = extendedDueDateInputRef.current?.value;
    if (!isValidDateFormat(value)) {
      setExtendedDueDateValidationError({
        error: true,
        message: t('EXTENSIONS.EXTENDED_DUE_DATE.ERROR.INVALID_DATE'),
      });
    } else {
      setExtendedDueDateValidationError(initialValidationState);
      setExtendedDueDateTimeValidationError(
        validateExtendedDueDateInputRange(
          parsedClassDueDateTimeInLocale,
          studentExtendedDueDate,
          studentExtendedDueTime
        )
      );
    }
  };

  const handleExtendedDueTimeChange = (value?: string) => {
    onChangeStudentExtendedDueTime(value);

    if (isValidTimeFormat(value)) {
      setExtendedDueDateTimeValidationError(
        validateExtendedDueDateInputRange(
          parsedClassDueDateTimeInLocale,
          studentExtendedDueDate,
          studentExtendedDueTime
        )
      );
    }
  };

  useEffect(() => {
    setExtendedDueDateKey(prev => (prev === 0 ? 1 : 0));
    setExtendedDueTimeKey(value => !value);
    resetErrorStates();
  }, [onReset]);

  const resetErrorStates = () => {
    setExtendedDueDateValidationError(initialValidationState);
    setExtendedDueDateTimeValidationError(initialValidationState);
    onChangeStudentExtendedDueDate(undefined);
  };

  const getExtendedDueDateErrorMessage = () => {
    let message = '';
    if (extendedDueDateValidationError.error) {
      message = extendedDueDateValidationError.message;
    } else if (extendedDueDateTimeValidationError.error) {
      message = extendedDueDateTimeValidationError.message;
    }
    return message;
  };

  return (
    <Flex behavior={FlexBehavior.container} spacing={1}>
      <DatePicker
        ref={extendedDueDateInputRef}
        testId={'extendedDueDateField'}
        labelText={t('EXTENSIONS.EXTENDED_DUE_DATE.DATE_LABEL')}
        helperMessage={t('EXTENSIONS.EXTENDED_DUE_DATE.HELPER_MESSAGE', {
          classDueDate: getFormattedDate(parsedClassDueDateTimeInLocale),
        })}
        containerStyle={datePickerContainerStyle}
        onDateChange={handleExtendedDueDateChange}
        onInputChange={handleExtendedDueDateInputChange}
        onInputBlur={handleExtendedDueDateBlur}
        onBeforeInput={allowOnlyPatternInput}
        value={studentExtendedDueDate}
        minDate={getDateWithoutTime(parsedClassDueDateTimeInLocale)}
        key={extendedDueDateKey}
        errorMessage={getExtendedDueDateErrorMessage()}
      />
      <div onBeforeInput={allowOnlyDecimalInput}>
        <StyledTimePicker
          testId={'extendedDueTimeField'}
          isInErrorState={extendedDueDateTimeValidationError.error}
          labelText={t('EXTENSIONS.EXTENDED_DUE_DATE.TIME_LABEL')}
          containerStyle={timePickerContainerStyle}
          onChange={handleExtendedDueTimeChange}
          value={studentExtendedDueTime}
          key={extendedDueTimeKey.toString()}
        />
      </div>
    </Flex>
  );
};
